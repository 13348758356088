import { useTranslation } from 'react-i18next';
import { Badge } from '@urbansportsclub/components/Badge';
import HRIntegrationCard, {
  HRIntegrationStages,
} from 'components/organisms/HRIntegrationCard/HRIntegrationCard';
import { Company, HrStatus } from 'models/company';
import ConnectNow from 'assets/images/6connect.png';
import {
  ArrowRightIcon,
} from '@heroicons/react/16/solid';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HRCardClicked } from 'tracking/HrTracking';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Text } from '@urbansportsclub/components/Text';

interface IHRIntegrationProps {
  company?: Company;
  reloadCompany: () => void;
}

function FindOutMore(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex w-full justify-between items-center sm:items-end sm:justify-center flex-col space-y-12">
      <div>
        <img
          src={ConnectNow}
          alt="Connect Now"
          className="w-full h-full sm:w-[212px] sm:h-[120px] md:w-[376px] md:h-[220px]"
        />
      </div>
      <div
        className="flex items-center space-x-1 text-blue-respect-500 font-bold text-sm [&:hover]:cursor-pointer"
        onClick={() => {
          HRCardClicked('HR Integration');
          navigate('/hr-wizard');
        }}
      >
        <span>{t('hrwizard.card.cta')}</span>
        <ArrowRightIcon className="h-4 w-4" />
      </div>
    </div>
  );
}

function HRIntegration({
  company,
  reloadCompany,
}: IHRIntegrationProps): JSX.Element {
  const { t } = useTranslation();
  const [allowConnect, setAllowConnect] = useState<boolean>(false);

  useEffect(() => {
    if (company?.isHrIntegrationConnected) {
      setAllowConnect(true);
    }
  }, [company]);

  function getHRIntegrationStage(): HRIntegrationStages {
    if (company?.hrStatus === HrStatus.INVALID) {
      return HRIntegrationStages.Invalid;
    }

    if (!company?.isHrIntegrationConnected) {
      return HRIntegrationStages.Connect;
    }

    if (
      company?.isHrIntegrationConnected &&
      !company?.hrIntegrationLastSyncedAt
    ) {
      return HRIntegrationStages.Syncing;
    }

    return HRIntegrationStages.Connected;
  }

  function isConnected(): boolean {
    return company?.isHrIntegrationConnected || false;
  }

  return (
    <section className="info-section">
      <div className="flex flex-col space-y-8">
        <div className="flex justify-between flex-wrap sm:flex-nowrap">
          <div className="flex flex-col space-y-8 mb-4">
            <div className="flex flex-col">
              <div className="flex space-x-2">
                <h2 className="capitalize heading-5">
                  {t('account_page.integration.title')}
                </h2>
                <Badge>New</Badge>
              </div>
              <div>
                <span className="text-base text-normal">
                  {t('account_page.integration.subheading')}
                </span>
                {!isConnected() && (
                  <div className='w-full sm:hidden'>
                    <FindOutMore />
                  </div>
                )}
              </div>
            </div>
            <div>
            </div>
            <div className="info-section__row sm:max-w-[460px] mt-4">
              <HRIntegrationCard
                providerName={company?.hrIntegrationName}
                integrationStage={getHRIntegrationStage()}
                onConnectionSuccess={reloadCompany}
                lastSyncedAt={company?.hrIntegrationLastSyncedAt}
                allowConnect={allowConnect}
                setAllowConnect={setAllowConnect}
              />
            </div>
            { company?.hrStatus === HrStatus.INVALID && (
              <div className='flex gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-blue-respect-500 flex-shrink-0' />
                <Text intent='md'><span dangerouslySetInnerHTML={{ __html: t('hr_integration.reconnect.message') }} /></Text>
              </div>
            ) }
          </div>
          {!isConnected() && (
            <div className='w-full hidden sm:inline-block'>
              <FindOutMore />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default HRIntegration;
