import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import chosenCompanyVar from 'utils/graphql/localState/chosenCompany';
import './Header.scss';
import { useGetCompany } from 'services/companyService';
import axios from 'axios';
import { getFromLocalStorage } from 'utils/localStorage';
import { getCustomError } from 'utils/api/error';
import { pushDataLayerEvent, DataLayerEvents, EVENT_GROUP_NAME } from 'utils/dataLayer';
import { Button } from '@urbansportsclub/components/Button';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { DocumentChartBarIcon, TableCellsIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import TagField from 'components/molecules/TagsField/TagField';
import { ITabsState } from 'models/generic';
import NotificationBar from 'components/molecules/NotificationBar/NotificationBar';
import ContactUs from '../ContactUs/ContactUs';
import { Tabs } from '@urbansportsclub/components/Tabs';
import { TabsItem } from '@urbansportsclub/components/Tabs/Item';
import { Text } from '@urbansportsclub/components/Text';
import { Card } from '@urbansportsclub/components/Card';
import { ReportType, useGetEmployeeReport } from 'services/employeeService';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  exportMembership: { event_group: EVENT_GROUP_NAME, event_name: 'membership_list_exported' },
};

interface Props {
  search?: boolean;
  onSearchHandler?: React.ChangeEventHandler<HTMLInputElement>;
  heading: string;
  onToggle: React.MouseEventHandler;
  isMenuOpen: boolean;
  buttonText?: string;
  buttonOnClick?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  tabs?: ITabsState;
  tabTranslationKeyGroup?: string;
  tabClickHandler?: (activeTab: string) => void;
  loading?: boolean;
  badges?: string[];
  onRemove?: (value: string) => void;
  searchInputValue?: string;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  showDownloadList?: boolean;
  subHeading?: string;
  showContactUs?: boolean;
  banner?: any;
}

const Header = ({
  search = false,
  onSearchHandler,
  heading,
  onToggle,
  isMenuOpen,
  buttonText,
  buttonOnClick,
  tabs,
  tabClickHandler,
  tabTranslationKeyGroup,
  loading,
  badges,
  onRemove,
  searchInputValue,
  onPaste,
  showDownloadList = true,
  subHeading,
  showContactUs = false,
  banner,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const chosenCompany = chosenCompanyVar.init() ?? chosenCompanyVar.restore();
  const { data, getCompany } = useGetCompany();
  const { downloadReport } = useGetEmployeeReport();

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [showDownloadOptions, setShowDownloadOptions] = useState<boolean>(false);

  useEffect(() => {
    getCompany(chosenCompany ? chosenCompany.uuid : '');
  }, []);

  useEffect(() => {
  }, [badges, searchInputValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDownloadOptions(false);
      }
    };

    if (showDownloadOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDownloadOptions]);

  const onDownloadExport = (type: ReportType) => {
    pushDataLayerEvent(DATA_LAYER_EVENTS.exportMembership);
    const companyId = data?.company.id || '';
    const companyName = data?.company.name || '';
    downloadReport(companyId, companyName, type);
    setShowDownloadOptions(false);
  };

  return (
    <header className="header bg-grey-light-500">
      <NotificationBar />
      <div className="flex flex-col justify-end pt-6 pb-3 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:items-center sm:flex-row">
          <div className='space-y-3'>
            <div>
              <Text intent='3xl' weight='bold'>{heading}</Text>
            </div>
            {subHeading && <div>
                  <p className="text-base text-black-midnight-light-500">{subHeading}</p>
              </div>
            }
          </div>
          <div className='flex space-x-2 sm:ml-auto'>
            {
              (buttonText && buttonOnClick)
              && (
                <Button
                  onClick={buttonOnClick}
                  disabled={loading}
                  variant={'secondary'}
                  className='text-base'
                >
                  {buttonText}
                </Button>
              )
            }
            {showDownloadList && data && data.company && data.company.isDownloadCsvEnabled &&
              <div ref={dropdownRef} className='relative max-sm:mt-2'>
                <Button
                  onClick={() => setShowDownloadOptions((prev) => !prev)}
                  className={showDownloadOptions ? 'bg-blue-respect-700 hover:bg-blue-respect-700' : ''}
                  fullWidth
                >
                  <span>{t('header.download_csv_cta')}</span>
                  <ChevronDownIcon className='w-4 h-4 stroke-2 ml-3' />
                </Button>
                { showDownloadOptions && (
                  <Card className='rounded-md flex flex-col p-2 gap-2 absolute w-full z-10 bg-white mt-2.5'>
                    <div className='cursor-pointer px-4 py-2 hover:bg-grey-dark-50 flex gap-x-3' onClick={() => onDownloadExport(ReportType.CSV)}>
                      <DocumentChartBarIcon className='w-6 h-6 text-green-freedom-600 shrink-0'/>
                      <Text intent='md'>XLS Format</Text>
                    </div>
                    <div className='cursor-pointer px-4 py-2 hover:bg-grey-dark-50 flex gap-x-3' onClick={() => onDownloadExport(ReportType.EXCEL)}>
                      <TableCellsIcon className='w-6 h-6 text-blue-respect-500 shrink-0' />
                      <Text intent='md'>Excel Format</Text>
                    </div>
                  </Card>
                ) }
              </div>
            }
            {
              showContactUs && <ContactUs />
            }
           </div>
        </div>
        {
          search && (
            <div className='my-3'>
              <TagField
                name="search"
                type="text"
                placeholder={t('header.search_placeholder')}
                icon={<MagnifyingGlassIcon className='h-5 w-5 text-gray-400' />}
                onChange={onSearchHandler}
                badges={badges}
                onRemove={onRemove}
                value={searchInputValue}
                onPaste={onPaste}
              />
            </div>
          )
        }
      { banner?.() }
      {
        tabs && (
          <Tabs
            defaultOption={t(`${tabTranslationKeyGroup as string}${tabs?.tabs[0].generalState}`)}
            variant="primary"
            onChange={tabClickHandler}
            className='w-full max-w-[600px] border-0'
            key={tabs?.tabs.length}
          >
            {tabs?.tabs.map((tab, index) => (
              <TabsItem
                className={`
                  flex-1 text-center py-[11px] border bg-white dark:text-black overflow-hidden after:left-[-0.5px] after:right-[-0.5px] after:bottom-[0px] aria-selected:hover:border-b-transparent
                  ${index != 0 && 'border-l-0'}
                  ${index == 0 && 'rounded-tl-[6px] rounded-bl-[6px]'}
                  ${index == tabs?.tabs.length - 1 && 'rounded-tr-[6px] rounded-br-[6px]'}
                `}
                key={tab.generalState}
                value={t(`${tabTranslationKeyGroup as string}${tab.generalState}`)}
              >
                <span data-testId={tab.generalState}>{t(`${tabTranslationKeyGroup as string}${tab.generalState}`)}</span>
              </TabsItem>
            ))}
          </Tabs>
        )
      }
      </div>
    </header>
  );
};

export default Header;
