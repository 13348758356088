import React from 'react';
import { Alert } from '@urbansportsclub/components/Alert';
import { AlertTitle } from '@urbansportsclub/components/Alert/Title';
import { AlertContent } from '@urbansportsclub/components/Alert/Content';
import { useTranslation } from 'react-i18next';

const InvoiceAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert severity='info' showCloseButton={false} className='p-4 mb-6'>
      <AlertTitle>{t('invoice.info.title')}</AlertTitle>
      <AlertContent>{t('invoice.info.description')}</AlertContent>
    </Alert>
  );
};

export default InvoiceAlert;
