import React, { useContext } from 'react';
import { Checkbox } from '@urbansportsclub/components/Checkbox';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@urbansportsclub/components/Text';
import { Link } from 'react-router-dom';
import { CompanyContext } from 'contexts/CompanyContext';
import * as styles from './LegalText.variants';

interface ILegalText {
  allowConnect?: boolean;
  setAllowConnect?: (allowConnect: boolean) => void;
  hasError: boolean;
}

const LegalText = ({ allowConnect, setAllowConnect, hasError } : ILegalText) => {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);

  if (!company || company?.isHrIntegrationConnected) return <></>;

  const renderLink = () => (
    <Link
      target='_blank'
      to={t('legal_text.cta.url')}
      onClick={(e) => e.stopPropagation()}
      className='text-blue-respect-500 underline'>
        {t('legal_text.cta.title')}
    </Link>
  );

  const message = t('legal_text.message').split('{{href}}');

  return (
    <div className='px-6 py-4 bg-white flex gap-x-4 cursor-pointer' onClick={() => setAllowConnect?.(!allowConnect)}>
      <Checkbox
        className={styles.checkboxVariants({ hasError })}
        checked={allowConnect}
        onChange={() => setAllowConnect?.(!allowConnect)}
      />
      <Text intent='md'>
        {message[0]}
        {renderLink()}
        {message[1]}
      </Text>
    </div>
  );
};

export default LegalText;


