import React, { useState } from 'react';
import { Card } from '@urbansportsclub/components/Card';
import TemplateImg from 'assets/images/temporary-one-pager.png';
import { Button } from '@urbansportsclub/components/Button';
import { EyeIcon } from '@heroicons/react/24/outline';
import MarketingBox from '../MarketingBox';
import Dialog from 'components/atoms/Tailwind/Diaglog/Dialog';
import { Text } from '@urbansportsclub/components/Text';
import { useTranslation } from 'react-i18next';

const heading = 'Text message that make sense';
const body = `
  Hey Team! 💪 <br />
  We’re offering free SportsClub memberships to help you manage stress and boost your well-being. 🎉' <br/>
  Get access to: <br />
  🏋️‍♀️ Fitness classes to stay active <br />
  🧘‍♀️ Yoga and meditation for stress relief <br />
  🤝 Team activities to strengthen bonds <br />
  🏠 Virtual workouts to fit your schedule <br />
  It’s time to prioritize YOU — improve your balance and mental health today! <br />
  <br />
  Sign Up Here: http://urbansportsclub.com/yourcompany <br />
  <br />
  Let’s take care of ourselves and each other! 🏃‍♂️🏃‍♀️`;
const dataLayerKeyTitle = 'One Pager';

interface ITemplateItem {
  langKey: string
}

const TemplateItem = ({ langKey } : ITemplateItem) => {
  const { t } = useTranslation();
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

  return (
    <Card className='!shadow-none bg-white p-6 rounded-xl flex items-start'>
      <div className='max-w-[350px]'>
        <img src={TemplateImg} className='w-[350px] rounded-xl' />
        <div className='mt-4 flex justify-between'>
          <Button variant='secondary' onClick={() => setIsPreviewOpen(true)}><EyeIcon className='w-6 h-6 text-blue-respect-500 mr-2'/>{t('onepager.list_block.preview', { lng: langKey })}</Button>
          <Button>{t('onepager.list_block.download', { lng: langKey })}</Button>
        </div>
      </div>
      <div className='flex-1'>
        <MarketingBox
          datalayerFieldKey=''
          heading={heading}
          body={body}
          removeAdditionalSpace
          datalayerMarketingType={dataLayerKeyTitle}
        />
      </div>
      <Dialog
        isOpen={isPreviewOpen}
        onConfirm={() => console.log('here download preview')}
        onConfirmText={t('onepager.list_block.download', { lng: langKey })}
        onCancel={() => setIsPreviewOpen(false)}
        onCancelText={t('onepager.list_block.close_preview', { lng: langKey })}
        bodyStyles='max-h-[calc(85vh-100px)]'
      >
        <img src={TemplateImg} className='w-full rounded-xl' />
        <Text intent='md' className='text-grey-dark-700 inline-block mt-4'>
          {t('onepager.list_block.preview_note', { lng: langKey })}
        </Text>
      </Dialog>
    </Card>
  );
};

export default TemplateItem;
