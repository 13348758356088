import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { fetchCompanies } from 'services/companyService';
import { CustomError } from 'utils/api/error';
import { Company } from 'models/company';
import { BuildingLibraryIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Card } from '@urbansportsclub/components/Card';
import { Badge } from '@urbansportsclub/components/Badge';
import { Text } from '@urbansportsclub/components/Text';
import CompaniesMobDialog from './CompaniesMobDialog';
import { useTranslation } from 'react-i18next';

const Companies = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<{ id: string, name: string }[] | undefined>();
  const [isMobDialogOpen, setMobDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchCompanies()
      .then((response: AxiosResponse<Company[]> | CustomError) => {
        if ('data' in response) {
          const companies: { id: string, name: string }[] = [];
          response.data.forEach((item: Company) => {
            companies.push({ id: item.id, name: item.name });
          });

          setData(companies);
        }
      })
      .catch(() => {

      });
  }, []);

  if (!data || data.length == 0) return <></>;

  const renderDesktopContent = () => {
    return (
      <div className='hidden sm:inline-block w-full'>
        <div className='flex items-center gap-x-2 mb-2'>
          <BuildingLibraryIcon className='h-6 w-6 text-grey-dark-500'/>
          <Text intent='lg' weight='bold' as='h3'>{t('report_page.companies_header.desktop')}</Text>
          <Badge variant='secondary' className='bg-grey-dark-50 border-grey-dark-50 text-grey-dark-700'>{data.length}</Badge>
        </div>
        <Card className='p-4 pb-2 !shadow-none rounded-md bg-white-natural-500'>
          { data.map((company) => <Badge variant='secondary' className='bg-grey-dark-50 border-grey-dark-50 whitespace-nowrap inline-block mr-2 mb-2 inline-flex'>{company.name}</Badge>) }
        </Card>
      </div>
    );
  };

  const renderMobileContent = () => {
    return (
      <div className='sm:hidden'>
        <div className='flex items-center gap-x-2 mb-2'>
          <Text intent='md' weight='bold' as='h3'>{t('report_page.companies_header.mobile')}</Text>
        </div>
        <Card className='p-4 !shadow-none rounded-md bg-white-natural-500 flex gap-x-2 relative overflow-hidden'>
          { data?.[0] && <Badge variant='secondary' className='bg-grey-dark-50 border-grey-dark-50 whitespace-nowrap'>{data?.[0]?.name}</Badge> }
          { data?.[1] && <Badge variant='secondary' className='bg-grey-dark-50 border-grey-dark-50 whitespace-nowrap'>{data?.[1]?.name}</Badge> }
          <div className='absolute right-0 top-px h-[calc(100%-2px)] bg-white-natural-500 flex items-center pl-4 pr-3' onClick={() => setMobDialogOpen(true)}>
            <Text intent='sm' className='mr-2'>+{data.length - 2} More</Text>
            <ChevronDownIcon className='w-4 h-4 text-grey-dark-500' />
          </div>
        </Card>
      </div>
    );
  };

  return (
    <div className='mt-6'>
      { renderDesktopContent() }
      { renderMobileContent() }
      <CompaniesMobDialog isOpen={isMobDialogOpen} setOpen={setMobDialogOpen} data={data} />
    </div>
  );
};

export default Companies;
