import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Text } from '@urbansportsclub/components/Text';
import { Toggle } from '@urbansportsclub/components/Toggle';
import { Radio } from '@urbansportsclub/components/Radio';
import * as styles from './AutoApprove.variants';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';
import { useConfigureHri } from 'services/companyService';

const AutoApprove = () => {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext); 
  const { configureHri } = useConfigureHri();

  const [selectedMembership, setSelectedMembership] = useState<number>(0);
  const [isApproveOn, setApproveOn] = useState<boolean | undefined>();
  const [isCancelOn, setCancelOn] = useState<boolean | undefined>();

  useEffect(() => {
    setApproveOn(company?.hri?.isAutoApprove as boolean);
    setCancelOn(company?.hri?.isAutoCancel as boolean);
  }, [company]);

  const handleApproveToggle = () => {
    if (isApproveOn) {
      toast(t('account_page.auto_approve_cancel.toast.approve_off'));
    } else {
      toast(t('account_page.auto_approve_cancel.toast.approve_on'));
    }
    setApproveOn((prev) => !prev);
    configureHri(company?.id as string, !isApproveOn, !!isCancelOn);
  };

  const handleCancelToggle = () => {
    if (isCancelOn) {
      toast(t('account_page.auto_approve_cancel.toast.cancel_off'));
    } else {
      toast(t('account_page.auto_approve_cancel.toast.cancel_on'));
    }
    setCancelOn((prev) => !prev);
    configureHri(company?.id as string, !!isApproveOn, !isCancelOn);
  };

  if (isApproveOn === undefined || isCancelOn === undefined) return <></>;

  if (!company?.isHrIntegrationConnected || !company?.featureFlags?.isHriConfigEnabled) return <></>;

  return (
    <div className='px-6 py-4 bg-white'>
      <div className='flex'>
        <div className='flex flex-col'>
          <Text intent='sm' weight='bold'>
            {t('account_page.auto_approve_cancel.auto_approve.title')}
            { !isApproveOn && <Text intent='sm' weight='regular' className='text-grey-dark-500'> {t('account_page.auto_approve_cancel.is_off')}</Text> }
          </Text>
          <Text intent='sm'>{isApproveOn ? t('account_page.auto_approve_cancel.auto_approve.txt_enabled') : t('account_page.auto_approve_cancel.auto_approve.txt_disabled')}</Text>
        </div>
        <div className='h-fit' onClick={ handleApproveToggle }>
          <Toggle checked={isApproveOn}>{t('account_page.auto_approve_cance.auto_approve_title')}</Toggle>
        </div>
      </div>

      {/* Please do not delete this code. It will be used in the future */}
      {/* { isApproveOn && (
        <div className='flex flex-col mt-3 gap-2'>
          <Text intent='sm' weight='bold'>{t('account_page.auto_approve_cancel.membership.title')}</Text>
          <div className='flex items-center gap-4'>
            <Radio checked={selectedMembership === 0} onClick={() => setSelectedMembership(0)} className={styles.radio()}/>
            <Text intent='sm'>
              <Text intent='sm' weight='bold'>{t('account_page.auto_approve_cancel.membership.immediatelly')} </Text>
              {t('account_page.auto_approve_cancel.membership.immediatelly_txt')}
            </Text>
          </div>
          <div className='flex items-center gap-4'>
            <Radio checked={selectedMembership === 1} onClick={() => setSelectedMembership(1)} className={styles.radio()}/>
            <Text intent='sm'>
              <Text intent='sm' weight='bold'>{t('account_page.auto_approve_cancel.membership.month')} </Text> 
              {t('account_page.auto_approve_cancel.membership.month_txt')}
            </Text>
          </div>
        </div>
      ) } */}

      <div className='flex border-t border-grey-natural-500 py-3 mt-3'>
        <div className='flex flex-col'>
          <Text intent='sm' weight='bold'>
            {t('account_page.auto_approve_cancel.auto_cancel.title')}
            { !isCancelOn && <Text intent='sm' weight='regular' className='text-grey-dark-500'> {t('account_page.auto_approve_cancel.is_off')}</Text> }
          </Text>
          <Text intent='sm'>{isCancelOn ? t('account_page.auto_approve_cancel.auto_cancel.txt_enabled') : t('account_page.auto_approve_cancel.auto_cancel.txt_disabled')}</Text>
        </div>
        <div className='h-fit' onClick={handleCancelToggle}>
          <Toggle checked={isCancelOn}>{t('account_page.auto_approve_cance.auto_cancel_title')}</Toggle>
        </div>
      </div>
    </div>
  );
};

export default AutoApprove;
