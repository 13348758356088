interface Key {
  [key: string]: string;
}

interface FeatureFlags {
  isReportsEnabled: boolean;
  isDownloadCsvEnabled: boolean;
  isApprovalEnabled: boolean;
  isOnboardingQuestionsEnabled: boolean;
  isUserManagementEnabled: boolean;
  isHrIntegrationEnabled: boolean;
  isContactFormEnabled: boolean;
  isInvoiceEnabled?: boolean;
  isUpsellEnabled?: boolean;
  isHriConfigEnabled?: boolean;
}

interface TermsAgreement {
  hris: boolean;
  marketing: boolean;
}

interface Hri {
  isAutoApprove: boolean;
  isAutoCancel: boolean;
}

export enum HrStatus {
  ACTIVE = 'ACTIVE',
  INVALID = 'INVALID',
}

export interface Company {
  _id: string;
  id: string;
  name: string;
  emailDomain?: string;
  cooperationStartDate?: string;
  employeeSignUpUrl?: string;
  websiteUrl?: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  accountManagerName?: string;
  accountManagerEmail?: string;
  accountManagerPhone?: string;
  isDownloadCsvEnabled?: boolean;
  isApprovalEnabled?: boolean;
  integrationName: string;
  onboardingAnswers: Key;
  isOnboardingQuestionsEnabled: boolean;
  isHrIntegrationConnected: boolean;
  hrConnectionLink: string;
  hrIntegrationName: string;
  hrIntegrationLastSyncedAt?: string;
  hri?: Hri;
  hrStatus?: HrStatus.ACTIVE | HrStatus.INVALID;
  featureFlags: FeatureFlags;
  csmType?: string;
  termsAgreement: TermsAgreement;
  billingCustomerId?: string;
}
