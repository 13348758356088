import { tv } from 'tailwind-variants';

export const footerDiv = tv({
  base: 'flex flex-col mt-5 gap-3 sm:mt-6 sm:grid sm:grid-flow-row-denses sm:flex-row',
  variants: {
    cols: {
      true: 'sm:grid-cols-2 sm:gap-3',
    },
    showFooter: {
      true: 'flex sm:grid',
      false: '!hidden',
    },
  },
});

export const titleStyles = tv({
  base: 'text-2xl font-bold text-black-midnight-light-800',
  variants: {
    align: {
      left: 'text-left',
      center: 'text-center',
    },
  },
});

export const descriptionStyles = tv({
  base: 'mt-2 text-base',
  variants: {
    align: {
      left: 'text-left',
      center: 'text-center',
    },
  },
});

export const iconStyles = tv({
  base: 'w-6 h-6 text-grey-dark-500 cursor-pointer stroke-2 absolute top-3 right-3 max-sm:hidden',
  variants: {
    iconVariant: {
      white: 'text-white',
      grey: 'text-grey-dark-500',
    },
    isHeaderImg: {
      true: 'top-3 right-3',
      false: 'top-4 right-4 md:top-6 md:right-6',
    },
  },
});

export const bodyStyles = tv({
  base: 'mt-2 scrollbar overflow-y-auto max-h-[calc(60vh-100px)] -mr-3 pr-3',
  variants: {
    isHeaderImg: {
      true: 'max-h-[calc(50vh-100px)]',
      false: 'max-h-[calc(60vh-100px)]',
    },
  },
});

export const ctaButtonStyles = tv({
  base: 'flex-1',
  variants: {
    isBothButtons: {
      true: 'flex-1 max-sm:px-3 max-sm:py-1.5 max-sm:text-sm',
    },
  },
});
