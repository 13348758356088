import React, { useContext, useState } from 'react';
import Dialog from 'components/atoms/Tailwind/Diaglog/Dialog';
import HrConnectModalImg from 'assets/images/hr-connect-modal.png';
import { Badge } from '@urbansportsclub/components/Badge';
import { Text } from '@urbansportsclub/components/Text';
import { getFromLocalStorage, saveToLocalStorageWithExpiry } from 'utils/localStorage';
import { UserContext } from 'contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from 'contexts/CompanyContext';
import { useTranslation } from 'react-i18next';

interface IHRConnectModal {
  isDefault?: boolean
}

const HrConnectModal = ({ isDefault = true } : IHRConnectModal) => {
  const translationKey = isDefault ? 'upsell_modal' : 'upsell_modal_employees';
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleConfirm = () => {
    setIsOpen(false);
    navigate('/hr-wizard');
  };

  const handleCancel = () => {
    const storageKey = isDefault ? 'hrConnectModalDefault' : 'hrConnectModalEmployee';
    saveToLocalStorageWithExpiry(storageKey, true, 2, user?.id);
    setIsOpen(false);
  };

  const handleClose = () => {
    const storageKey = isDefault ? 'hrConnectModalDefault' : 'hrConnectModalEmployee';
    saveToLocalStorageWithExpiry(storageKey, true, 7, user?.id);
    setIsOpen(false);
  };

  const isDismissedLocalStorage = getFromLocalStorage<any>(isDefault ? 'hrConnectModalDefault' : 'hrConnectModalEmployee');
  const isModalDismissed = isDismissedLocalStorage && isDismissedLocalStorage?.value && isDismissedLocalStorage?.userId == user?.id;

  if (!company?.featureFlags?.isUpsellEnabled || isModalDismissed || company?.isHrIntegrationConnected) return <></>;

  const renderListItem = (itemName: string) => (
    <li className='flex gap-x-2'>
      <span className='shrink-0 relative w-1 h-1 rounded-full bg-black-midnight-light-500 top-2.5' />
      <Text intent='md' className=''>{itemName}</Text>
    </li>
  );

  return (
    <Dialog
      isOpen={isOpen}
      onConfirmText={t(`${translationKey}.actions.confirm`)}
      onConfirmVariant='primary'
      onConfirm={handleConfirm}
      onCancelText={t(`${translationKey}.actions.cancel`)}
      onCancelVariant='secondary'
      onCancel={handleCancel}
      headerImage={HrConnectModalImg}
      iconVariant='white'
      showCloseIcon
      onClose={handleClose}
    >
      { isDefault && <Badge className='mb-3 sm:mb-2' mode='dark'>🌟 {t('upsell_modal.badge')}</Badge> }
      <Text intent='xl' weight='bold' className='mb-2 inline-block'>{t(`${translationKey}.title`)}</Text>
      <div>
        { isDefault && <Text intent='md' weight='bold' className='hidden sm:block mb-2'>{t('upsell_modal.description')}</Text> }
        <Text intent='md' className='block'>{t(`${translationKey}.list.title`)}</Text>
        <ul>
          {renderListItem(t(`${translationKey}.list.item_1`))}
          {renderListItem(t(`${translationKey}.list.item_2`))}
          {renderListItem(t(`${translationKey}.list.item_3`))}
        </ul>
      </div>
    </Dialog>
  );
};

export default HrConnectModal;
