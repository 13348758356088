import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import { Tabs } from '@urbansportsclub/components/Tabs';
import { TabsItem } from '@urbansportsclub/components/Tabs/Item';
import TermsAndConditions from 'components/organisms/TermsAndConditions/TermsAndConditions';
import { useTranslation } from 'react-i18next';
import InfoBlock from 'components/molecules/InfoBlock/InfoBlock';
import { getFromLocalStorage, saveToLocalStorageWithExpiry } from 'utils/localStorage';
import { useContext, useState } from 'react';
import { UserContext } from 'contexts/UserContext';
import OnePagerComponent from './OnePager/OnePager';
import MessageTemplate from './MessageTemplate/MessageTemplate';
import { isDevEnv } from 'utils/helpers';

enum TabsKey {
  Message = 'Message Template',
  OnePager = 'One Pager Downloads',
}

function MarketingHub() {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [isInfoVisible, setIsInfoVisible] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<TabsKey>(TabsKey.Message);
  
  const handleDismissBtn = () => {
    saveToLocalStorageWithExpiry('isInfoDismissed', true, 100, user?.id);
    setIsInfoVisible(false);
  };

  const onChangeTab = (value: string) => {
    setActiveTab(value as TabsKey);
  };

  const isInfoDismissed = getFromLocalStorage<any>('isInfoDismissed')?.value;

  return (
    <SidebarLayout heading={t('menu.marketing_hub')} showDownloadList={false} >
      <div className="flex flex-col w-full sm:-mt-5">
        <div className="flex w-full border-r-0 border-b border-grey-natural-500">
          <Tabs
            defaultOption="Message Template"
            variant="primary"
            mode="light"
            className="border-0 hidden sm:flex"
            onChange={onChangeTab}
          >
            <TabsItem value="Message Template">{t('marketing.tab.message')}</TabsItem>
            { isDevEnv() && <TabsItem value="One Pager Downloads">One Pager Downloads</TabsItem> }
          </Tabs>
          <span className='hidden sm:block relative py-4 px-4 text-base font-normal whitespace-nowrap text-black-midnight-light-500/40'>
            {t('marketing.tab.coming_soon')}
          </span>
        </div>
        <div className='mt-8 flex flex-col space-y-4'>
          { !isInfoDismissed && isInfoVisible && ( // TODO: update the logic once the data is saved in marketing_info field on the BE
            <InfoBlock
              title={t('marketing.info.title')}
              content={t('marketing.info.content')}
              btnText={t('marketing.info.btn')}
              onClick={handleDismissBtn}
            />
          )}
          { activeTab === TabsKey.Message && <MessageTemplate /> }
          { isDevEnv() && activeTab === TabsKey.OnePager && <OnePagerComponent /> }
        </div>
      </div>
      {/* <TermsAndConditions page={MARKETING} isBackgroundDisabled isShrinked /> */}
    </SidebarLayout>
  );
}

export default MarketingHub;
