import React, { useContext } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { Button } from '@urbansportsclub/components/Button';
import { Badge } from '@urbansportsclub/components/Badge';
import { CompanyContext } from 'contexts/CompanyContext';
import { useDownloadInvoiceVersion } from 'services/invoiceService';
import { Text } from '@urbansportsclub/components/Text';
import { useTranslation } from 'react-i18next';

interface IInvoiceVersion {
  version: number;
  invoiceId: string;
  documentId: string;
  downloadItemLink: string;
  isCurrent?: boolean;
  createdOn: string;
}

const InvoiceVersion = ({ version, invoiceId, documentId, downloadItemLink, createdOn, isCurrent = false } : IInvoiceVersion) => {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { getDownloadInvoiceVersion } = useDownloadInvoiceVersion();

  const handleDownloadInvoice = async (e: any) => {
    e.stopPropagation();
    await getDownloadInvoiceVersion(company?.id as string, company?.name as string, invoiceId, downloadItemLink, documentId);
  };

  return (
    <div>
      <div className='flex items-center'>
        <span className='border border-grey-dark-200 rounded-full w-2 h-2 inline-block mr-3 relative left-[0.5px]' />
        <Text as='span' intent='md' weight='medium'  className='mr-2'>{t('invoice.version')} {version}</Text>
        <span>
          {isCurrent ? <Badge variant='default' mode='dark'>{t('invoice.current')}</Badge> : <Text as='span' intent='sm' className='text-grey-dark-700'>{t('invoice.outdated')}</Text>}
        </span>
      </div>
      <div className='text-sm flex flex-col sm:flex-row items-start sm:items-center gap-y-2 gap-x-6 ml-1 pl-4 pt-4 pb-2 border-l border-grey-dark-200 border-l-[1px]'>
        <Text as='span' intent='sm'>{t('invoice.created')} {createdOn.split(' ')[0].replace(/-/g, '.')}</Text>
        <Button className='bg-transparent p-0 h-[auto]' variant='link' onClick={handleDownloadInvoice}>
          {t('invoice.download')}
        </Button>
      </div>
    </div>
  );
};

export default InvoiceVersion;