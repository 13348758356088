import {  SquaresPlusIcon } from '@heroicons/react/24/outline';
import { Badge } from '@urbansportsclub/components/Badge';
import Dialog from 'components/atoms/Tailwind/Diaglog/Dialog';
import { useCallback, useContext, useEffect, useState } from 'react';
import { showKomboConnect } from '@kombo-api/connect';
import { useAcceptTerms, useDisconnectHRIntegration, useReconnectHRIntegration, useSaveHRIntegration } from 'services/companyService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils/helpers';
import { formatDateTime } from 'utils/date';
import { CompanyContext } from 'contexts/CompanyContext';
import { AxiosResponse } from 'axios';
import API from 'utils/api';
import { useAppContext } from 'context/AppContext';
import { HRConnectionFailed, HRConnectNow, HRDisconnectCancel, HRDisconnectNow } from 'tracking/HrTracking';
import AutoApprove from './AutoApprove';
import LegalText from './LegalText';
import { Button } from '@urbansportsclub/components/Button';

import { ExclamationTriangleIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import * as styles from './HRIntegrationCard.variants';

export enum HRIntegrationStages {
  Connect = 'connect',
  Connecting = 'connecting',
  Connected = 'connected',
  Syncing = 'syncing',
  Synced = 'synced',
  Invalid = 'INVALID',
}

interface IHRIntegrationCardProps {
  providerName?: string;
  integrationStage: HRIntegrationStages;
  onConnectionSuccess?: () => void;
  lastSyncedAt?: string;
  allowConnect?: boolean;
  setAllowConnect?: (allowConnect: boolean) => void;
}


const integrationStagesButtonText = {
  connect: 'Connect Now',
  connecting: 'Connecting',
  connected: 'Disconnect',
  syncing: 'Syncing Now',
  synced: 'Synced',
  INVALID: 'hr_integration.reconnect.btn',
};

const integrationStagesSecondaryText = {
  connect: '',
  connecting: 'hr_integration.card.connecting2',
  connected: 'hr_integration.card.connected',
  syncing: 'hr_integration.card.syncing',
  synced: 'Last sync:',
  INVALID: 'hr_integration.reconnect.btn',
};

export interface IGetActivationTokenResponse {
  link: string;
}

function HRIntegrationCard({ providerName, integrationStage, onConnectionSuccess, lastSyncedAt, allowConnect, setAllowConnect }: IHRIntegrationCardProps) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentIntegrationStage, setCurrentIntegrationStage] = useState(integrationStage);
  const [disabled, setDisabled] = useState(false);
  const [isDisabledBtnClicked, setDisabledBtnClicked] = useState(false);
  const { disconnectHRIntegration, loading, hasError, requestFinished } = useDisconnectHRIntegration();
  const { reconnectHRIntegration, loading: loadingReconnection } = useReconnectHRIntegration();
  const { saveHRIntegration, requestFinished: saveHRIntegrationRequestFinished, hasError: saveHRIntegrationHasError, data:hrIntegration } = useSaveHRIntegration();
  const { acceptTerms } = useAcceptTerms();
  const { t } = useTranslation();
  const { setPolling, setShowSyncing, company, reloadCompany } = useContext(CompanyContext);
  const { language } = useAppContext();

  const getCompanyId = useCallback(() => {
    if (company) return company.id;

    return '';
  }, [company]);

  useEffect(() => {
    if (integrationStage === HRIntegrationStages.Syncing) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [integrationStage]);

  useEffect(() => {
    setDisabled(!allowConnect);
  }, [allowConnect]);

  useEffect(() => {
    if (requestFinished || saveHRIntegrationRequestFinished) {
      let toastMessage = '';
      
      if (!hasError && requestFinished && currentIntegrationStage === HRIntegrationStages.Connected) {
        setIsOpenModal(false);
        toastMessage = t('hr_integration.toast.disconnected');
        
      } else if (!saveHRIntegrationHasError && saveHRIntegrationRequestFinished && currentIntegrationStage === HRIntegrationStages.Connecting && hrIntegration) {
        acceptTerms(getCompanyId(), 'hris');
        setCurrentIntegrationStage(HRIntegrationStages.Connected);
        setDisabled(false);
        toastMessage = t('hr_integration.toast.syncing');
        setPolling(true);
        setShowSyncing(true);
      }

      if (toastMessage) {
        toast(toastMessage, {
          position: 'top-right',
        });
        onConnectionSuccess && onConnectionSuccess();
      }
    }

  }, [requestFinished, hasError, onConnectionSuccess, currentIntegrationStage, saveHRIntegrationRequestFinished, saveHRIntegrationHasError, hrIntegration, t, getCompanyId, acceptTerms, setPolling, setShowSyncing]);

  function onClick() {
    if (HRIntegrationStages.Invalid === currentIntegrationStage) {
      komboReConnect();
    } else if (HRIntegrationStages.Connected === currentIntegrationStage) {
      HRDisconnectNow();
      setIsOpenModal(true);
    } else if (HRIntegrationStages.Connect === currentIntegrationStage) {
      setCurrentIntegrationStage(HRIntegrationStages.Connecting);
      setDisabled(true);
      kommboConnect();
      HRConnectNow();
    }
  }

  function onDisconnect() {
    disconnectHRIntegration(getCompanyId());
  }

  async function komboReConnect() {
    try {
      const mainConnectionLink = await reconnectHRIntegration(company?.id as string);
      if (mainConnectionLink) {
        await showKomboConnect(mainConnectionLink as string);
        setPolling(true);
        setShowSyncing(true);
        setDisabled(true);
      }
      reloadCompany?.();
    } catch (error) {
      HRConnectionFailed();
      setCurrentIntegrationStage(HRIntegrationStages.Invalid);
      setDisabled(false);
    }
  }

  async function kommboConnect() {
    try {
      const mainConnectionLink = await getIntegrationConnectionLink();
      const activationToken = await showKomboConnect(mainConnectionLink);
      saveHRIntegration(getCompanyId(), activationToken);
    } catch (error) {
      HRConnectionFailed();
      setCurrentIntegrationStage(HRIntegrationStages.Connect);
      setDisabled(false);
    }
  }

  async function getIntegrationConnectionLink(): Promise<string> {
    const response: AxiosResponse<IGetActivationTokenResponse> =
      await API.get(
        '/company/' + getCompanyId() + '/hr-integration-link',
      );
    return response.data.link.replace('lng=en', 'lng=' + language);
  }

  function getHRIntegrationButtonText() {
    if (HRIntegrationStages.Invalid === currentIntegrationStage) {
      return (
        <div className='flex items-center justify-center'>
          <ArrowPathIcon className='w-6 h-6 mr-1' />
          {t(integrationStagesButtonText[currentIntegrationStage])}
        </div>
      );
    }

    return integrationStagesButtonText[currentIntegrationStage];
  }

  function getHRIntegrationSecondaryText() {  
    if (HRIntegrationStages.Invalid === currentIntegrationStage) {
      return t('hr_integration.reconnect.error');
    }

    if (currentIntegrationStage === HRIntegrationStages.Connected && lastSyncedAt) {   
      return t(integrationStagesSecondaryText[currentIntegrationStage])
        .replace('{{synced_time}}', formatDateTime(new Date(lastSyncedAt || '')));
    }

    return t(integrationStagesSecondaryText[currentIntegrationStage]);
  }

  function translateAndReplaceIntegrationName(translate_key: string, value: string) {
    return t(translate_key).replace('{{integration_name}}', value);
  }

  return (
    <>
        <Dialog 
            isOpen={isOpenModal}
            title={t('hr_integration.pop_header.title')}
            titleVariant='center'
            description={t('hr_integration.pop_header.description')}
            descriptionVariant='center'
            onCancel={() => {
              HRDisconnectCancel();
              setIsOpenModal(false);
            }}
            onConfirm={onDisconnect}
            onConfirmText={translateAndReplaceIntegrationName('hr_integration.pop_header.primary_button', capitalizeFirstLetter(providerName || ''))}
            loading={loading}
            onCancelText={t('hr_integration.pop_header.secondary_button')}
            onConfirmVariant='danger'
            badge='danger'
        />
        <div className="overflow-hidden rounded-xl border border-grey-natural-500">
          <div>
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-grey-dark-50 p-6">
                <div className={styles.iconContainer({ isInvalid: HRIntegrationStages.Invalid === currentIntegrationStage })}>
                    {
                        !providerName ? (
                          <SquaresPlusIcon className='h-6 w-6 text-grey-dark-500'/>
                        ) : (
                          HRIntegrationStages.Invalid !== currentIntegrationStage ? (
                            <img
                              src={`https://storage.googleapis.com/kombo-assets/integrations/${providerName || ''}/icon.svg`}
                              alt={providerName || ''}
                              className="h-6 w-6"
                            />
                          ) : (
                            <ExclamationTriangleIcon className='w-6 h-6 text-system-error-500' />
                          )
                        )
                    }
                </div>
                <div className="flex flex-col">
                   <div className='flex flex-col sm:flex-row sm:gap-x-2'>
                       <span className='text-base font-bold'>{t('hr_integration.card.header')}</span>
                      {HRIntegrationStages.Connected === integrationStage &&
                        <span>
                          <Badge variant='secondary' className='gap-x-2'>
                            <span>Connected</span>
                            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-[#2EBA88]">
                              <circle r={3} cx={3} cy={3} />
                            </svg>
                          </Badge>
                        </span>
                      }
                    </div>
                    <span className='text-sm font-normal'>{getHRIntegrationSecondaryText()}</span>
                </div>
                <div
                  className='relative ml-auto'
                  onClick={() => {
                    if (disabled) setDisabledBtnClicked(true);
                  }}
                >
                    <Button
                      className={disabled ? 'pointer-events-none h-9' : 'h-9'}
                      variant={HRIntegrationStages.Invalid === currentIntegrationStage ? 'secondary' : 'primary'}
                      onClick={onClick}
                      disabled={disabled || loadingReconnection}
                      small
                    >
                      {getHRIntegrationButtonText()}
                    </Button>
                </div>
            </div>
            <div>
              <AutoApprove />
            </div>
            <div>
              <LegalText allowConnect={allowConnect} setAllowConnect={setAllowConnect} hasError={isDisabledBtnClicked}/>
            </div>
          </div>
            <dl className="hidden -my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Heading 1</dt>
                <dd className="text-gray-700">
                    Button 1
                </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Heading 2</dt>
                <dd className="flex items-start gap-x-2">
                    Button 2
                </dd>
                </div>
            </dl>
        </div>
    </>
  );
}


export default HRIntegrationCard;