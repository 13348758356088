import React, { useState } from 'react';

import { Dropdown } from '@urbansportsclub/components/Dropdown';
import { DropdownContent } from '@urbansportsclub/components/Dropdown/Content';
import { DropdownItem } from '@urbansportsclub/components/Dropdown/Item';
import { DropdownTrigger } from '@urbansportsclub/components/Dropdown/Trigger';
import { Text } from '@urbansportsclub/components/Text';
import { LANGUAGES } from 'utils/constants';

interface ILanguageSwitcher {
  setLangKey: (langKey: string) => void;
}

const LanguageSwitcher = ({ setLangKey } : ILanguageSwitcher) => {
  const [activeLang, setActiveLang] = useState<string>(LANGUAGES[0].name);

  const handleLanguageChange = (value: any) => {
    setActiveLang(value);
    
    const lng = LANGUAGES.find(item => item.name === value);
    setLangKey(lng?.code as string);
  };

  return (
    <Dropdown
      value={activeLang}
      variant="combobox"
      mode="light"
      onChange={handleLanguageChange}
    >
      <DropdownTrigger className="w-full inline-flex justify-between items-center text-left py-3 pl-4 pr-1 w-[250px] bg-white">
        {activeLang}
      </DropdownTrigger>
      <DropdownContent side="bottom" className="z-10 content-stretch justify-start min-w-1">
        {LANGUAGES.map((item) => (
          <DropdownItem key={item.code} value={item.name} className='py-2 px-3 [&>svg]:hidden'>
            {item.name}
          </DropdownItem>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

export default LanguageSwitcher;