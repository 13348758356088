import { tv } from 'tailwind-variants';

export const checkboxVariants = tv({
  base: 'shrink-0 mt-1',
  variants: {
    hasError: {
      true: 'border-system-error-500',
    },
  },
});
