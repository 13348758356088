import React from 'react';
import MarketingDiv, { IMarketingDivProps } from '../MarketingDiv';


const marketingContent: IMarketingDivProps[] = [
  {
    textKey: 'welcome',
    defaultExpanded: true,
    addSlack: true,
    dataLayerKeyTitle: 'Welcome Email',
  },
  {
    textKey: 'signup',
    defaultExpanded: false,
    addSlack: false,
    dataLayerKeyTitle: 'Signup Email',
  },
  {
    textKey: 'activation',
    defaultExpanded: false,
    addSlack: false,
    dataLayerKeyTitle: 'Activation Email',
  },
];

const MessageTemplate = () => {
  return (
    <>
      {marketingContent.map((content) =>
        <MarketingDiv
          key={content.textKey}
          defaultExpanded={content.defaultExpanded}
          textKey={content.textKey}
          addSlack={content.addSlack} 
          dataLayerKeyTitle={content.dataLayerKeyTitle}/>)
      }
    </>
  );
};

export default MessageTemplate;