import React, { useContext } from 'react';
import { ArrowsUpDownIcon } from '@heroicons/react/24/solid';
import Dialog from 'components/atoms/Tailwind/Diaglog/Dialog';
import { Text } from '@urbansportsclub/components/Text';
import { Employee, Personnel } from 'models/employee';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';
import { capitalizeFirstLetter } from 'utils/helpers';

interface ILinkConfirmationDialog {
  isOpen: boolean,
  onCancel: () => void,
  onConfirm: () => void,
  uscEmployee?: Employee | undefined,
  hriEmployee?: Personnel | undefined;
}

const LinkConfirmationDialog = ({ isOpen, onCancel, onConfirm, uscEmployee, hriEmployee } : ILinkConfirmationDialog) => {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);

  if (!company) return <></>;

  return (
    <Dialog
      title={t('hri_sync.dialog.title')}
      description={t('hri_sync.dialog.description').replace(/HRI_SYSTEM/g, capitalizeFirstLetter(company?.hrIntegrationName))}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onCancelText={t('hri_sync.actions.cancel_match')}
      onConfirmText={t('hri_sync.actions.confirm_and_sync')}
      badge='primary'
    >
      <div className='mt-6'>
        <div className='flex flex-col gap-y-1'>
          <Text as='span' intent='md' className='text-grey-dark-700'>Urban Sports Club Account</Text>
          <Text as='span' intent='md' weight='bold'>{uscEmployee?.firstName} {uscEmployee?.lastName}</Text>
          <Text as='span' intent='md'>{uscEmployee?.email}</Text>
        </div>
        <div className="flex items-center justify-center space-x-2 my-4">  
          <hr className="flex-grow border-t border-grey-natural-500" />  
          <ArrowsUpDownIcon className='w-4 h-4 text-grey-dark-500'/>
          <hr className="flex-grow border-t border-grey-natural-500" />  
        </div>
        <div className='flex flex-col gap-y-1'>
          <Text as='span' intent='md' className='text-grey-dark-700'>{capitalizeFirstLetter(company?.hrIntegrationName)} Data</Text>
          <Text as='span' intent='md' weight='bold'>{hriEmployee?.first_name} {hriEmployee?.last_name}</Text>
          <Text as='span' intent='md'>{hriEmployee?.email}</Text>
        </div>
      </div>
    </Dialog>
  );
};

export default LinkConfirmationDialog;
