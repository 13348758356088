import { ReactNode } from 'react';

import './Form.scss';

export enum FormMethod {
  POST = 'post',
  GET = 'get',
  DIALOG = 'dialog',
}

interface Props {
  method?: FormMethod,
  action?: string,
  children: ReactNode,
  onSubmit: React.FormEventHandler,
  error?: string | ReactNode;
  className?: string;
}

const Form = ({
  method = FormMethod.GET,
  action = '',
  children,
  onSubmit,
  error = '',
  className,
}: Props): JSX.Element => (
  <form className={'form' + (className ? ` ${className}` : '') } method={method} action={action} onSubmit={onSubmit}>
    {children}
    {error && <span className="form__error">{error}</span>}
  </form>
);

export default Form;
