import { pushToDataLayer } from 'utils/dataLayer';


export const SelectOrganizationReports = () => {
  pushToDataLayer({
    event: 'button_clicked',
    item: 'b2b_portal_org_overview',
    details: 'select_organization',
    origin: 'choose-company',
  });
};


export const DownloadOrganizationReports = () => {
  pushToDataLayer({
    event: 'button_clicked',
    item: 'Download',
    details: 'enterprise_reports',
    origin: 'Organization Overview',
  });
};