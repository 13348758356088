import { getCustomError, CustomError } from 'utils/api/error';
import { useCallback, useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getRequestConfig } from 'utils/api/requestConfig';
import { Company } from 'models/company';
import { CompanyData } from 'utils/graphql/queries/company';
import API from 'utils/api';
import { CompanyActivation } from 'models/companyActivation';
import { HRIntegration } from 'models/integration';
import { HRConnectedSuccess, HRConnectionFailed, HRDisconnectionFailed, HRDisconnectionSuccess } from 'tracking/HrTracking';
import { HRIS, MARKETING } from 'utils/constants';

export const fetchCompany = async (companyId: string): Promise<AxiosResponse<Company>> => {
  return axios
    .get(`companies/${companyId}`, getRequestConfig())
    .then((response: AxiosResponse<Company>) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const useGetCompany = () => {
  const [data, setData] = useState<CompanyData | undefined>(undefined);
  const [getCompanyLoading, setGetCompanyLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const getCompany = async (companyId: string) => {
    setGetCompanyLoading(true);
    return fetchCompany(companyId).then((response: AxiosResponse<Company>) => {
      const companyData: { company: Company } = { company: response.data };
      setData(companyData);
      setGetCompanyLoading(false);
    })
      .catch((error) => {
        setHasError(true);
        errorMsg = getCustomError(error);
        setGetCompanyLoading(false);
      });
  };

  return {
    getCompany,
    getCompanyLoading,
    errorMsg,
    hasError,
    data,
  };
};

export const fetchCompanies = async (): Promise<AxiosResponse<Company[]> | CustomError> => {
  const acceptJsonConfig: AxiosRequestConfig = {
    headers: {
      accept: 'application/json',
    },
  };
  const config = getRequestConfig(acceptJsonConfig);

  return axios.get(
    'companies?itemsPerPage=100',
    config,
  )
    .then((response: AxiosResponse<Company[]>) => {
      return response;
    })
    .catch((error) => {
      return  getCustomError(error);
    });
};


export const useAnswerCompanyQuestions = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const answerCompanyQuestions = async (companyId: string, answers: object) => {
    setLoading(true);
    return API
      .put(`/companies/${companyId}/onboarding/answers`, {
        answers,
      })
      .then((response: AxiosResponse) => {
        setLoading(false);
      })
      .catch((error) => {
        errorMsg = getCustomError(error);
        setHasError(true);
        setLoading(false);
      });
  };

  return {
    answerCompanyQuestions,
    loading,
    errorMsg,
    hasError,
  };
};

export const useAnswerCompanyActivationQuestion = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState<CustomError | undefined>();
  const [employeeSignupUrl, setEmployeeSignupUrl] = useState<string>('');
  let errorMsg: CustomError;

  const answerCompanyActivationQuestions = async (reactivationQuestionId:string, answer:string, reasonKey: string, reasonDescription?:string) => {
    setLoading(true);
    return API.put(`/reactivation_questions/${reactivationQuestionId}`, {
      answer,
      reasonKey,
      reasonDescription,
    })
      .then((r: AxiosResponse) => {
        const companyActivation: CompanyActivation =
          r.data as CompanyActivation;
        setLoading(false);
        setEmployeeSignupUrl(companyActivation.landingPageUrl);
      })
      .catch((error: AxiosError) => {
        errorMsg = getCustomError(error);
        errorMsg.code = error.response?.status ?? 500;
        setHasError(errorMsg);
        setLoading(false);
      });
  };

  return {
    answerCompanyActivationQuestions,
    loading,
    hasError,
    employeeSignupUrl,
  };
};

export const useDisconnectHRIntegration = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);

  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const disconnectHRIntegration = async (companyId: string) => {
    setLoading(true);
    return API.deleteResource(`/company/${companyId}/hr-integration`)
      .then(() => {
        setLoading(false);
        setRequestFinished(true);
        HRDisconnectionSuccess();
      })
      .catch((error) => {
        errorMsg = getCustomError(error);
        setHasError(true);
        setLoading(false);
        setRequestFinished(true);
        HRDisconnectionFailed();
      });
  };

  return {
    disconnectHRIntegration,
    loading,
    errorMsg,
    hasError,
    requestFinished,
  };
};

export const useReconnectHRIntegration = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const reconnectHRIntegration = async (companyId: string) => {
    setLoading(true);
    return API.get(`/company/${companyId}/hr-reconnection-link`)
      .then((data: any) => {
        setLoading(false);
        return data?.data?.link as string;
      })
      .catch((error) => {
        errorMsg = getCustomError(error);
        setHasError(true);
        setLoading(false);
        return false;
      });
  };

  return {
    reconnectHRIntegration,
    loading,
    errorMsg,
    hasError,
  };
};

export const useSaveHRIntegration = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);
  const [data, setData] = useState<HRIntegration | null>(null);

  let errorMsg: CustomError = { code: 200, message: 'No error' };

  const saveHRIntegration = async (
    companyId: string,
    token: string,
  ) => {
    setLoading(true);
    return API.put(`/companies/${companyId}/hr-integration`, {
      token,
    })
      .then((r:AxiosResponse) => {
        const hrIntegration = r.data as HRIntegration;
        setData(hrIntegration);
        setLoading(false);
        setRequestFinished(true);
        HRConnectedSuccess();
      })
      .catch((error) => {
        errorMsg = getCustomError(error);
        setHasError(true);
        setLoading(false);
        setRequestFinished(true);
        HRConnectionFailed();
      });
  };

  return {
    saveHRIntegration,
    loading,
    errorMsg,
    hasError,
    requestFinished,
    data,
  };
};

export const useAcceptTerms = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);

  const acceptTerms = useCallback(async (companyId: string, termsId: typeof HRIS | typeof MARKETING) => {
    setLoading(true);
    return API.post(`/companies/${companyId}/terms-agreement`, {
      terms_id: termsId,
    })
      .then(() => {
        setLoading(false);
        setRequestFinished(true);
      })
      .catch(() => {
        setHasError(true);
        setLoading(false);
        setRequestFinished(true);
      });
  }, []);
  return {
    loading,
    hasError,
    requestFinished,
    acceptTerms,
  };
};

export const useConfigureHri = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const configureHri = async (companyId: string, autoApprove: boolean, autoCancel: boolean) => {
    setLoading(true);
    return API.post(`/companies/${companyId}/configure-hri`, {
      autoApprove,
      autoCancel,
    })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  };

  return {
    loading,
    hasError,
    configureHri,
  };
};
