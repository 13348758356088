import React, { useContext, useEffect } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { Button } from '@urbansportsclub/components/Button';
import { Badge } from '@urbansportsclub/components/Badge';
import { CompanyContext } from 'contexts/CompanyContext';
import { useDownloadInvoice } from 'services/invoiceService';
import { Text } from '@urbansportsclub/components/Text';
import i18n from 'i18next';
import { formatDate } from 'utils/date';
import { useTranslation } from 'react-i18next';

interface IInvoiceHeading {
  invoiceId: string,
  invoiceNumber: string,
  invoiceDate: string,
  invoiceTotal: string,
  isNew?: boolean
}

const InvoiceHeading = ({ invoiceId, invoiceNumber, invoiceDate, invoiceTotal, isNew = false } : IInvoiceHeading) => {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { getDownloadLink } = useDownloadInvoice();

  const handleDownloadVersion = async (e: any) => {
    e.stopPropagation();
    await getDownloadLink(company?.id as string, company?.name as string, invoiceId, invoiceNumber);
  };

  const formatCurrency = (amount: string): string => {
    const locale: string = i18n.language || 'de';

    return (parseInt(amount) / 100).toLocaleString(locale, { style: 'currency', currency: 'EUR' });
  };

  return (
    <>
      <div className='hidden md:flex'>
        <Text as='span' intent='md' weight='medium' className='flex-[1.5] flex gap-x-2'>
          {t('invoice.invoice')} {invoiceNumber}
          {isNew && <Badge variant='default' mode='dark' className='relative bottom-[1px]'>New</Badge>}
        </Text>
        <Text as='span' intent='md' className='flex-1'>{formatDate(new Date(invoiceDate))}</Text>
        <Text as='span' intent='md' className='flex-1'>{formatCurrency(invoiceTotal)}</Text>
        <Button className='text-sm bg-transparent p-0 h-[auto] mr-4' variant='link' onClick={handleDownloadVersion}>
          {t('invoice.download')}
        </Button>
      </div>
      <div className='flex flex-col gap-y-2 items-start md:hidden'>
        <Text as='span' intent='md' weight='medium' className='flex-1 flex gap-x-2 items-center'>
          {t('invoice.invoice')} {invoiceNumber}
          {isNew && <Badge variant='default' mode='dark'>New</Badge>}
        </Text>
        <div className='flex gap-x-10'>
          <Text as='span' intent='md' className='flex-1 text-grey-dark-700'>{formatDate(new Date(invoiceDate))}</Text>
          <Text as='span' intent='md' className='flex-1 text-grey-dark-700'>{formatCurrency(invoiceTotal)}</Text>
        </div>
        <Button className='text-sm bg-transparent p-0 h-[auto] mr-4 relative left-[-3px]' variant='link' onClick={handleDownloadVersion}>
          {t('invoice.download')}
        </Button>
      </div>
    </>
  );
};

export default InvoiceHeading;
