import React, { useContext, useEffect } from 'react';
import { Accordion } from '@urbansportsclub/components/Accordion';
import { AccordionDetails } from '@urbansportsclub/components/Accordion/Details';
import { AccordionSummary } from '@urbansportsclub/components/Accordion/Summary';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import InvoiceVersion from './InvoiceVersion';
import InvoiceHeading from './InvoiceHeading';
import { CompanyContext } from 'contexts/CompanyContext';
import { useGetInvoiceVersions } from 'services/invoiceService';
import { Text } from '@urbansportsclub/components/Text';
import { useTranslation } from 'react-i18next';

interface IInvoice {
  id: string,
  number: string,
  documentDate: string,
  amount: number,
  currency: string,
  isNew?: boolean,
}

const Invoice = ({ id, number, documentDate, amount, currency, isNew = false } : IInvoice) => {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { data, getInvoceVersions } = useGetInvoiceVersions();

  useEffect(() => {
    if (company?.id) {
      getInvoceVersions(company.id, id);
    }
  }, [company?.id]);

  return (
    <Accordion className='bg-white-natural-500 border border-[1px] border-grey-dark-200 rounded-md contain-paint mb-4'>
      <AccordionSummary
        expandIcon={<ChevronDownIcon className={'text-grey-dark-500 h-auto w-6 h-6'} />}
        className='[&>span:first-child]:w-[100%] [&>button]:py-4 [&>button]:px-6 [&>button]:md:py-5 [&>button]:md:px-6 [&>button]:!bg-transparent [&>button]:border-none'
      >
        <InvoiceHeading
          invoiceNumber={number}
          invoiceDate={documentDate}
          invoiceTotal={`${amount} €`}
          invoiceId={id}
          isNew={isNew}
        />
      </AccordionSummary>
      <AccordionDetails className='text-base max-w-[calc(100%-48px)] mx-auto py-6 border-t border-t-[1px] border-grey-dark-200 box-border'>
        <Text as='h3' intent='md' className='mb-3' >{t('invoice.history')}</Text>
        <div>
          {data && data.map((version: any, index: number) => 
            <InvoiceVersion
              version={data.length - index}
              createdOn={version.created_at}
              invoiceId={id}
              documentId={version.document_id}
              downloadItemLink={version.items[0].download_item_link}
              isCurrent={index === 0}
            />,
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Invoice;
