import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@urbansportsclub/components/Text';
import { GiftIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { getFromLocalStorage, saveToLocalStorageWithExpiry } from 'utils/localStorage';
import { UserContext } from 'contexts/UserContext';
import { CompanyContext } from 'contexts/CompanyContext';
import { Link } from 'react-router-dom';

const CancelationBanner = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  const [showBanner, setShowBanner] = useState<boolean>(true);

  const handleCloseBanner = () => {
    setShowBanner(false);
    saveToLocalStorageWithExpiry('hideCancelBanner', true, 2, user?.id);
  };

  useEffect(() => {
    const hideCancelBanner = getFromLocalStorage<any>('hideCancelBanner');

    if (hideCancelBanner && hideCancelBanner.userId == user?.id) {
      setShowBanner(false);
    }

    if (company?.isHrIntegrationConnected) {
      setShowBanner(false);
    }
  }, []);

  if (!showBanner || !company?.featureFlags?.isUpsellEnabled) return <></>;

  return (
    <div className='bg-green-freedom-600 flex p-4 md:py-6 mt-4 rounded-xl'>
      <GiftIcon className='h-6 w-6 text-white flex-shrink-0' />
      <div className='ml-4 mr-5'>
        <Text intent='md' weight='bold' className='text-white block'>
          {t('employees_page.cancel_banner.text')} <Link to='/hr-wizard' className='underline'>{t('employees_page.cancel_banner.link')}</Link>
        </Text>
      </div>
      <XMarkIcon className='h-6 w-6 text-white flex-shrink-0 cursor-pointer max-sm:hidden' onClick={handleCloseBanner} />
    </div>
  );
};

export default CancelationBanner;
