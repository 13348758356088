import { RouteProps } from 'react-router-dom';

import Login from 'components/pages/Login';
import CompanyChoice from 'components/pages/CompanyChoice';
import ForgottenPassword from 'components/pages/ForgottenPassword';
import ForgottenPasswordSuccess from 'components/pages/ForgottenPasswordSuccess';
import ResetPassword from 'components/pages/ResetPassword';
import PasswordSet from 'components/pages/PasswordSet';
import Home from 'components/pages/Home';
import Account from 'components/pages/Account';
import TermsOfUse from 'components/pages/TermsOfUse';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import NotFound from './components/pages/NotFound';
import DevRoutes from 'routes-dev';
import OnBoarding from 'components/pages/Onboarding/Onboarding';
import MarketingHub from 'components/pages/MarketingHub/MarketingHub';
import Report from 'components/pages/Report';
import {
  DocumentDuplicateIcon,
  UsersIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
  DocumentChartBarIcon,
} from '@heroicons/react/24/outline';
import { ForwardRefExoticComponent,  RefAttributes,  SVGProps } from 'react';
import Support from 'components/pages/Support/Support';
import HRWizard from 'components/pages/HRWizard/HRWizard';
import HRSucces from 'components/pages/HRSuccess/HRSuccess';
import { ADMIN_ROLES, UserRole } from 'contexts/UserContext';
import {
  CursorArrowRaysIcon, CurrencyEuroIcon,
} from '@heroicons/react/24/outline';
import DownloadReport from 'components/pages/Report/DownloadReport';
import Invoices from 'components/pages/Invoices/Invoices';
import MatchDataManually from 'components/pages/MatchDataManually/MatchDataManually';
import EnterpriseReports from 'components/pages/EnterpriseReports/EnterpriseReports';

type IconType = ForwardRefExoticComponent<
Omit<SVGProps<SVGSVGElement>, 'ref'> & {
  title?: string | undefined;
  titleId?: string | undefined;
} & RefAttributes<SVGSVGElement>
>;

export type RouterProps = RouteProps & {
  isPrivate?: boolean;
  component: () => JSX.Element;
  name?: string;
  addInMenu?: boolean;
  icon?: IconType;
  allowedRoles?: string[];
  isNew?: boolean;
  featuredFlag?: string;
  allowedIntegration?: string[];
  // Page Name for DataLayer
  pageName: string;
  allowedCompanyProps?: string;
};

const routes: RouterProps[] = [
  {
    path: '/login',
    component: Login,
    pageName: 'login',
  },
  {
    path: '/forgot-password',
    component: ForgottenPassword,
    pageName: 'forgot_password',
  },
  {
    path: '/forgot-password/email',
    component: ForgottenPasswordSuccess,
    pageName: 'forgot_password_email',
  },
  {
    path: '/forgot-password/new',
    component: ResetPassword,
    pageName: 'new_password_setup',
  },
  {
    path: '/forgot-password/success',
    component: PasswordSet,
    pageName: 'success_password',
  },
  {
    path: '/choose-company',
    component: CompanyChoice,
    isPrivate: true,
    pageName: 'choose_company',
  },
  {
    path: '/home',
    component: Home,
    isPrivate: true,
    name: 'menu.employees_link',
    addInMenu: true,
    allowedRoles: [...ADMIN_ROLES, UserRole.HR],
    allowedIntegration: ['uscweb', 'onefit'],
    icon: UsersIcon,
    pageName: 'employees',
  },
  {
    path: '/',
    component: Home,
    isPrivate: true,
    pageName: 'employees',
  },
  {
    path: '/account',
    component: Account,
    isPrivate: true,
    name: 'menu.account_link',
    addInMenu: true,
    allowedRoles: [...ADMIN_ROLES, UserRole.Finance, UserRole.HR],
    allowedIntegration: ['uscweb', 'onefit'],
    icon: Cog8ToothIcon,
    pageName: 'account',
  },
  {
    path: '/terms-of-use',
    component: TermsOfUse,
    pageName: 'terms_of_use',
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    pageName: 'privacy_policy',
  },
  {
    path: '/onboarding',
    component: OnBoarding,
    isPrivate: true,
    pageName: 'onboarding',
  },
  {
    path: '/marketing-hub',
    isPrivate: true,
    isNew: false,
    component: MarketingHub,
    icon: CursorArrowRaysIcon,
    addInMenu: true,
    allowedRoles: [...ADMIN_ROLES, UserRole.HR],
    allowedIntegration: ['uscweb'],
    name: 'menu.marketing_hub',
    pageName: 'marketing_hub',
  },
  {
    path: '/invoices',
    isPrivate: true,
    isNew: true,
    component: Invoices,
    icon: CurrencyEuroIcon,
    addInMenu: true,
    allowedRoles: [...ADMIN_ROLES, UserRole.HR, UserRole.Finance],
    allowedIntegration: ['uscweb'],
    name: 'invoice.title',
    pageName: 'invoices',
    allowedCompanyProps: 'featureFlags[\'isInvoiceEnabled\']',
  },
  {
    path: '/report',
    component: Report,
    isPrivate: true,
    name: 'menu.report_link',
    addInMenu: true,
    isNew: false,
    featuredFlag: 'isReportsEnabled',
    icon: DocumentDuplicateIcon,
    allowedRoles: [...ADMIN_ROLES, UserRole.Finance, UserRole.HR],
    pageName: 'report',
  },
  {
    path: '/reports/:year/:month',
    component: DownloadReport,
    isPrivate: true,
    name: 'menu.report_link',
    featuredFlag: 'isReportsEnabled',
    allowedRoles: [...ADMIN_ROLES],
    pageName: 'report_organization_download',
  },
  {
    path: '/enterprise-reports',
    component: EnterpriseReports,
    isPrivate: true,
    addInMenu: true,
    name: 'report_page.organization_reports',
    icon: DocumentChartBarIcon,
    allowedIntegration: ['organization'],
    allowedRoles: [...ADMIN_ROLES],
    pageName: 'enterprise_reports',
  },
  {
    path: '/support',
    isPrivate: true,
    isNew: false,
    icon: QuestionMarkCircleIcon,
    addInMenu: true,
    component: Support,
    allowedRoles: [...ADMIN_ROLES, UserRole.Finance],
    allowedIntegration: ['uscweb', 'organization'],
    name: 'Support',
    pageName: 'support',
  },
  {
    path: '/hr-wizard',
    isPrivate: true,
    isNew: true,
    addInMenu: false,
    component: HRWizard,
    name: 'Hr Wizard',
    pageName: 'hr_wizard',
  },
  {
    path: '/hr-success',
    isPrivate: true,
    isNew: true,
    addInMenu: false,
    component: HRSucces,
    name: 'Hr Success',
    pageName: 'hr_success',
  },
  {
    path: '/hri-sync',
    isPrivate: true,
    isNew: false,
    addInMenu: false,
    component: MatchDataManually,
    name: 'Match Data Manually',
    pageName: 'hri_sync',
  },
  {
    path: '*',
    component: NotFound,
    pageName: 'not_found',
  },
];

if (['localhost', 'portal-staging', 'b2b-portal'].includes(window.location.hostname.split('.')[0])) {
  routes.push(...DevRoutes);
}

export default routes;
