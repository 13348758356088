import React, { useEffect } from 'react';
import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import { useGetEnterpriseReports } from 'services/reportService';
import { Text } from '@urbansportsclub/components/Text';
import { EnterpriseReport } from 'models/report';
import Companies from './Companies';
import { useTranslation } from 'react-i18next';
import ReportCard from 'components/molecules/ReportCard/ReportCard';

const EnterpriseReports = () => {
  const { t } = useTranslation();
  const { data, getEnterpriseReports } = useGetEnterpriseReports();

  useEffect(() => {
    getEnterpriseReports();
  }, []);

  const renderContent = () => {
    return (
      <div className='mt-6 flex flex-col gap-2'>
        { data['hydra:member'].map((item: EnterpriseReport, index: number) => <ReportCard data={item} isLatest={index == 0} />) }
      </div>
    );
  };

  return (
    <SidebarLayout heading={`${t('report_page.organization_reports')}`} showDownloadList={false} className='relative'>
      <Text as='h3' intent='md'>{t('report_page.subtitle')}</Text>
      <Companies />
      { data && data['hydra:totalItems'] > 0 ? renderContent() : <></> }
    </SidebarLayout>
  );
};

export default EnterpriseReports;
