import React, { useEffect, useState } from 'react';
import ImageBlock from './ImageBlock';
import { Divider } from 'components/atoms/Tailwind/Divider/Divider';
import TemplateList from './TemplateList';

const OnePager = () => {
  const [image, setImage] = useState<string | null>(null);

  return (
    <div className='py-8'>
      <ImageBlock image={image} setImage={setImage} />
      <div className='my-8'>
        <Divider/>
      </div>
      <TemplateList />
    </div>
  );
};

export default OnePager;