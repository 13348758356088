import { useState } from 'react';
import API from 'utils/api';
import ExcelJS from 'exceljs';
import download from 'downloadjs';
import { AxiosResponse } from 'axios';

export enum ReportType {
  CSV = 'csv',
  EXCEL = 'excel',
}

export const useGetEmployeeReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const downloadReport = async (companyId: string, companyName: string, type: ReportType) => {
    setLoading(true);
    return API.get(`/employees/export?company.id=${companyId}`, {}, { responseType: 'blob' })
      .then((response: AxiosResponse) => {
        if (type === ReportType.CSV) downloadCSV(companyName, response);
        if (type === ReportType.EXCEL) downloadEXCEL(companyName, response);
      })
      .catch((err) => setHasError(true));
  };

  return {
    loading,
    hasError,
    downloadReport,
  };
};

const downloadCSV = (companyName: string, response: AxiosResponse) => {
  const blob = new Blob([response.data], { type: 'text/csv' });
  download(blob, `${companyName}_urbansportsclub_memberships.csv`, 'text/csv');
};

const downloadEXCEL = (companyName: string, response: AxiosResponse) => {
  const reader = new FileReader();
  reader.onload = async () => {
    const csvData = reader.result as string;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    const rows = csvData.split('\n').map((row) => row.split(','));
    rows.forEach((row) => {
      worksheet.addRow(row);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const uint8Array = new Uint8Array(buffer);

    download(uint8Array, `${companyName}_urbansportsclub_memberships.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  };

  reader.readAsText(response.data);
};
