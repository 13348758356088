import { tv } from 'tailwind-variants';

export const iconContainer = tv({
  base: 'flex shrink-0 justify-center items-center h-12 w-12 rounded-lg bg-white object-cover ring-1 ring-gray-900/10',
  variants: {
    isInvalid: {
      true: 'ring-system-error-500 bg-system-error-50',
    },
  },
});
