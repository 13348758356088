import { CompanyContext } from 'contexts/CompanyContext';
import download from 'downloadjs';
import { useContext, useState } from 'react';
import API from 'utils/api';

export const useGetInvoices = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const getInvoces = async (companyId: string, startDate?: string, endDate?: string) => {
    setLoading(true);
    let queryStr = '';
    if (startDate && endDate) {
      queryStr = `?date_from=${startDate}&date_to=${endDate}`;
    }

    return API.get(`/companies/${companyId}/invoices${queryStr}`)
      .then((response: any) => {
        setLoading(false);

        const result = [];
        for (let i = response.data.items.length - 1; i >= 0; i--) {
          result.push(response.data.items[i]);
        }

        setData(result);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  };

  return {
    loading,
    hasError,
    getInvoces,
    data,
  };
};

export const useDownloadInvoice = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const getDownloadLink = async (companyId: string, companyName: string, invoiceId: string, invoiceNumber: string) => {
    setLoading(true);
    return API.get(`/companies/${companyId}/invoices/${invoiceId}/download`, {}, { responseType: 'blob' })
      .then((response: any) => {
        const blob = new Blob([response.data]);
        download(blob, `${companyName}-${invoiceNumber}.pdf`, 'application/pdf');
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  };

  return {
    loading,
    hasError,
    getDownloadLink,
  };
};


export const useGetInvoiceVersions = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const getInvoceVersions = async (companyId: string, invoiceId: string) => {
    setLoading(true);
    return API.get(`companies/${companyId}/invoices/${invoiceId}/versions`)
      .then((response: any) => {
        setLoading(false);
        setData(response.data.items);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  };

  return {
    loading,
    hasError,
    getInvoceVersions,
    data,
  };
};


export const useDownloadInvoiceVersion = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const getDownloadInvoiceVersion = async (companyId: string, companyName: string, invoiceId: string, invoiceVersion: string, docoumentId: string) => {
    setLoading(true);
    return API.get(`/companies/${companyId}/invoices/${invoiceId}/version/download?version=${invoiceVersion}`, {}, { responseType: 'blob' })
      .then((response: any) => {
        setLoading(false);
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${companyName}-${docoumentId}.pdf`;
        
        document.body.appendChild(link);
        link.click();
        
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  };

  return {
    loading,
    hasError,
    getDownloadInvoiceVersion,
  };
};
