import { Badge } from '@urbansportsclub/components/Badge';
import { Dropdown } from '@urbansportsclub/components/Dropdown';
import { Text } from '@urbansportsclub/components/Text';
import React, { useState } from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import TemplateItem from './TemplateItem';
import { LANGUAGES } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const TemplateList = () => {
  const { t } = useTranslation();
  const [langKey, setLangKey] = useState<string>(LANGUAGES[0].code);

  const renderLanguageSwitcher = () => {
    return (
      <div className='flex flex-col gap-y-2'>
        <Text intent='md' weight='bold'>{t('onepager.list_block.select_language', { lng: langKey })}</Text>
        <LanguageSwitcher setLangKey={setLangKey} />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className='flex flex-col gap-y-1'>
        <Text intent='lg' weight='bold' className='flex items-center gap-x-2'>
          {t('onepager.list_block.title', { lng: langKey })}
          <Badge mode='dark'>{t('onepager.list_block.new', { lng: langKey })}</Badge>
        </Text>
        <Text intent='md'>{t('onepager.list_block.subtitle', { lng: langKey })}</Text>
      </div>
    );
  };

  return (
    <div>
      <div className='flex gap-x-4 items-center justify-between mb-6'>
        {renderHeader()}
        {renderLanguageSwitcher()}
      </div>
      <div className='flex flex-col gap-y-6'>
        <TemplateItem langKey={langKey} />
        <TemplateItem langKey={langKey} />
        <TemplateItem langKey={langKey} />
      </div>
    </div>
  );
};

export default TemplateList;
