import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Text } from '@urbansportsclub/components/Text';
import { Button } from '@urbansportsclub/components/Button';
import { Badge } from '@urbansportsclub/components/Badge';
import { Checkbox } from '@urbansportsclub/components/Checkbox';
import { useTranslation } from 'react-i18next';

interface ICompaniesMobDialog {
  data: { id: string, name: string }[],
  isOpen: boolean,
  setOpen: (isOpen: boolean) => void,
}

const CompaniesMobDialog = ({ data, isOpen, setOpen } : ICompaniesMobDialog) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    setOpen(false);
  };

  if (!isOpen) return <></>;

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-[9999]' onClick={() => setOpen(false)}>
      <div className='w-10/12 h-full bg-white shadow-lg flex flex-col rounded-tl-lg' onClick={(e) => e.stopPropagation()}>
        <div className='p-4 border-b border-grey-natural-500 flex justify-between items-start'>
          <div>
            <Text as='h2' intent='md' weight='bold' className='flex items-center gap-x-2'>
              {t('report_page.companies_header.mobile')}
              <Badge variant='secondary' className='pt-[1px] bg-grey-natural-200 text-grey-dark-700 border-grey-natural-500'>{data.length}</Badge>
            </Text>
            <Text as='p' intent='md' className='!mt-2'>{t('report_page.mobile_dialog.title')}</Text>
          </div>
          <div>
            <XMarkIcon className='h-5 w-5 text-gray-400 cursor-pointer' onClick={() => setOpen(false)} />
          </div>
        </div>


        <div className='flex-1 overflow-y-auto py-4 px-2'>
          { data.map((item) => (
            <div className='flex items-center justify-between p-2'>
              <Text intent='md' className='mr-3'>{item.name}</Text>
              <Checkbox disabled checked />
            </div>
          )) }
        </div>

        <div className='p-4 border-t border-grey-natural-500'>
          <Button small fullWidth onClick={onSubmit}>{t('report_page.mobile_dialog.footer')}</Button>
        </div>
      </div>
    </div>
  );
};

export default CompaniesMobDialog;
