import { Fragment, useState } from 'react';
import { Dialog as TailwindDialog, Transition } from '@headlessui/react';
import { Button } from '@urbansportsclub/components/Button';
import { ExclamationTriangleIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import * as styles from './Dialog.styles';

interface IDialog {
  children?: React.ReactNode;
  title?: string;
  titleVariant?: 'left' | 'center';
  description?: string;
  descriptionVariant?: 'left' | 'center';
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  onConfirmText?: string;
  onCancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onConfirmVariant?: 'primary' | 'secondary' | 'danger' | 'danger-secondary';
  onCancelVariant?: 'primary' | 'secondary' | 'danger' | 'danger-secondary';
  onClose?: () => void;
  headerImage?: string;
  iconVariant?: 'white' | 'grey';
  showCloseIcon?: boolean;
  loading?: boolean;
  disabled?: boolean;
  badge?: 'primary' | 'secondary' | 'danger';
  footerBanner?: React.ReactNode;
  bodyStyles?: string,
}

export default function Dialog({
  children,
  title,
  titleVariant = 'left',
  description,
  descriptionVariant = 'left',
  isOpen,
  setIsOpen,
  onConfirmText,
  onCancelText,
  onConfirm,
  onCancel,
  onConfirmVariant = 'primary',
  onCancelVariant = 'secondary',
  onClose,
  headerImage,
  iconVariant = 'grey',
  showCloseIcon,
  loading,
  disabled,
  badge,
  footerBanner,
  bodyStyles,
} : IDialog) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <TailwindDialog as="div" className="relative z-[60]" onClose={() => onClose?.()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end sm:items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <TailwindDialog.Panel className="relative w-full sm:max-w-lg transform overflow-hidden rounded-lg bg-white p-4 md:px-8 md:py-6 text-left align-middle shadow-xl transition-all">
                <div>
                  { headerImage && (
                    <div className='w-[calc(100%+32px)] md:w-[calc(100%+64px)] -m-4 md:-mx-8 md:-my-6 mb-4 md:mb-6'>
                      <img
                        src={headerImage}
                        alt="Hr Connect Modal"
                        className='w-full'
                      />
                    </div>
                  )}
                  {badge === 'danger' && <div className="mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-orange-passion-50">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>}
                  {badge === 'primary' && <div className="mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-green-freedom-50">
                    <CheckIcon className="h-6 w-6 text-green-freedom-600" aria-hidden="true" />
                  </div>}
                  <TailwindDialog.Title as="h3" className={ styles.titleStyles({ align: titleVariant }) }>
                    { title }
                  </TailwindDialog.Title>
                  <div className={ styles.descriptionStyles({ align: descriptionVariant }) }>{description}</div>
                  { showCloseIcon && <XMarkIcon className={ styles.iconStyles({ iconVariant: iconVariant, isHeaderImg: !!headerImage }) } onClick={onClose}/> }
                </div>

                <div className={styles.bodyStyles({ isHeaderImg: !!headerImage, className: bodyStyles })}>  
                  {children}
                </div>

                <div className="mt-4 flex gap-4 flex-col sm:flex-row">
                  { onCancelText && <Button className='flex-1' variant={onCancelVariant} onClick={onCancel} disabled={loading}>{onCancelText}</Button> }
                  { onConfirmText && <Button className='flex-1' variant={onConfirmVariant} onClick={onConfirm} disabled={loading || disabled}>{onConfirmText}</Button> }
                </div>

                { footerBanner }
              </TailwindDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </TailwindDialog>
    </Transition>
  );
}
