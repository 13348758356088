import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@urbansportsclub/components/Button';
import { Text } from '@urbansportsclub/components/Text';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';

interface IImageUploader {
  image: string | null,
  setImage: (image: string | null) => void,
  allowedImgTypes?: string[],
  onUploadCallback?: (image: string) => void,
  onRemoveCallback?: (image: string) => void,
  minImgSize: number,
  successMessage?: string,
  errorMessage?: string,
  infoMessage?: string,
}

const ImageUploader = ({
  image,
  setImage,
  allowedImgTypes = [],
  onUploadCallback,
  onRemoveCallback,
  minImgSize,
  successMessage = i18next.t('image_uploader.success_message'),
  errorMessage = i18next.t('image_uploader.error_message'),
  infoMessage,
} : IImageUploader) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);

    const file = event.target.files?.[0];
    if (!file) return;

    if (!allowedImgTypes.includes(file.type)) {
      return setError(true);
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;

      const img = new Image();
      img.onload = () => {
        if (img.height < minImgSize) {
          return setError(true);
        }

        setImage(base64String);
        onUploadCallback?.(base64String);
      };
      img.src = base64String;
    };

    reader.readAsDataURL(file);
    toast.success(successMessage);
  };

  const handleRemoveImage = () => {
    onRemoveCallback?.(image as string);
    setImage(null);
  };
  
  return (
    <div className='flex gap-x-4 items-center'>
      {image && (
        <div className='bg-white border border-2 border-grey-natural-500 rounded-md p-2 relative'>
          <XCircleIcon
            className='w-6 h-6 text-grey-dark-500 absolute right-0.5 top-0.5 z-10 cursor-pointer'
            onClick={handleRemoveImage}
          />
          <img src={image} alt="Uploaded" className="max-w-[250px] max-h-[90px] w-full" />
        </div>
      )}
      <div className='flex flex-col gap-y-4 max-w-[225px]'>
        <Button className='max-w-[185px]' variant='secondary' onClick={() => fileInputRef.current?.click()}>
          <PlusIcon className='w-5 h-5 text-blue-respect-500' />
          {t('image_uploader.cta_text')}
        </Button>
        { error ? (
          <Text intent='sm' className='text-system-error-500'>{errorMessage}</Text>
        ) : (
          <Text intent='sm' className='text-grey-dark-700'>{infoMessage}</Text>
        ) }
        <input type="file" accept="image/png, image/gif" ref={fileInputRef} className="hidden" onChange={handleFileChange} />
      </div>
    </div>
  );
};

export default ImageUploader;