import React, { useEffect, useRef, useState } from 'react';
import ImageUploader from 'components/organisms/ImageUploader/ImageUploader';
import { toast } from 'react-toastify';
import { Text } from '@urbansportsclub/components/Text';
import { Button } from '@urbansportsclub/components/Button';
import { useTranslation } from 'react-i18next';

interface IImageBlock {
  image: string | null,
  setImage: (image: string | null) => void,
}

const MIN_IMG_SIZE = 80;

const ImageBlock = ({ image, setImage } : IImageBlock) => {
  const { t } = useTranslation();

  useEffect(() => {
    setImage(localStorage.getItem('marketingHubLogo'));
  }, []);

  const onUpload = (img: string) => {
    localStorage.setItem('marketingHubLogo', img);
  };

  const onRemove = (img: string) => {
    const prevImg = img;
    localStorage.removeItem('marketingHubLogo');

    const toastId = toast.info(
      <div className='flex flex-col gap-y-2'>
        <Text intent='md'>{t('onepager.image_uploader.remove_image_message')}</Text>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setImage(prevImg);
            localStorage.setItem('marketingHubLogo', prevImg);
            toast.dismiss(toastId);
          }}
          className='bg-transparent hover:bg-transparent active:bg-transparent text-blue-respect-700 w-fit h-auto p-0 shadow-none border-none'
        >
          {t('onepager.image_uploader.undo')}
        </Button>
      </div>,
    );
  };

  return (
    <div className='flex gap-x-8 items-center'>
      <div className='flex flex-col'>
        <Text intent='xl' weight='bold'>{t('onepager.image_uploader.block_title')}</Text>
        <Text intent='md'>{t('onepager.image_uploader.block_subtitle')}</Text>
      </div>
      <ImageUploader
        image={image}
        setImage={setImage}
        allowedImgTypes={['image/png', 'image/gif']}
        onUploadCallback={onUpload}
        onRemoveCallback={onRemove}
        minImgSize={MIN_IMG_SIZE}
        successMessage={t('onepager.image_uploader.success_message')}
        errorMessage={t('onepager.image_uploader.error_message')}
        infoMessage={t('onepager.image_uploader.info_message')}
      />
    </div>
  );
};

export default ImageBlock;