import React from 'react';
import { ReactComponent as NoInvoiceFoundImg } from 'assets/images/no-invoices-found.svg';
import { Text } from '@urbansportsclub/components/Text';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface INoInvoiceFound {
  isFiltered: string | null
}

const NoInvoiceFound = ({ isFiltered } : INoInvoiceFound) => {
  const { t } = useTranslation();

  return (
    <div className='relative md:top-[80px]'>
      <NoInvoiceFoundImg className='mx-auto w-[100%]' />
      { isFiltered ? (
        <div className='text-center mt-10 text-black-midnight-500'>
          <Text as='p' intent='md' weight='bold' className='mb-4'>{t('invoice.no_invoice.filtered')}</Text>
        </div>
      ) : (
        <div className='text-center mt-10 text-black-midnight-500'>
          <Text as='p' intent='md' weight='bold' className='mb-4'>{t('invoice.no_invoice.no_access')}</Text>
          <Text as='p' intent='md'>{t('invoice.no_invoice.contact_admins')} ({t('invoice.no_invoice.find_from')} <Link className='text-black-midnight-500 cursor-pointer underline' to='/account'>{t('invoice.no_invoice.account_page')} -{'>'} {t('invoice.no_invoice.manage_team')}</Link>)</Text>
          <Text as='p' intent='md'>{t('invoice.no_invoice.reach_out_to_us')} <Link className='text-black-midnight-500 cursor-pointer underline' to='/support'>{t('invoice.no_invoice.contact_us')}</Link> {t('invoice.no_invoice.help_form')}</Text>     
        </div>
      )}
    </div>
  );
};

export default NoInvoiceFound;
